import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { useNavigate } from 'react-router-dom'

import { UilArrowLeft } from '@iconscout/react-unicons'
import { Tooltip } from '@mui/material'
import { gaEvents } from '~/events'

import { truncateText } from '~/validations/truncateText'

import { IBackNavigationComponentProps } from './BackNavigation.interfaces'
import * as S from './BackNavigation.styles'

export const BackNavigationComponent = ({
  coMomentStatus,
  label,
  path,
  icon,
  tooltipText,
  name = 'Voltar',
  small,
}: IBackNavigationComponentProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [pathState, setPathState] = useState<string>('')
  const [coMomentStatusState, setCoMomentStatusState] = useState<string>('')

  useEffect(() => {
    if (path) {
      setPathState(path)
    }
    if (coMomentStatus) {
      setCoMomentStatusState(coMomentStatus)
    }
  }, [path, coMomentStatus])

  useEffect(() => {
    const parentPaths = ['/home', '/schedule', '/schedule/week', '/schedule/month', '/trail', '/class']

    if (location.state?.from) {
      const from = location.state?.from
      const isParentPage = parentPaths.some((parentPath) => from.startsWith(parentPath))

      if (isParentPage) {
        localStorage.setItem('@LEKTO:Navigation', from)
      } else {
        localStorage.removeItem('@LEKTO:Navigation')
      }
    }
  }, [location.state])

  const goBack = () => {
    const previousParent = localStorage.getItem('@LEKTO:Navigation')
    if (path && path.includes('/class-details/')) {
      gaEvents.eventBackNavigationButton()
    }

    if (coMomentStatusState.length > 0) {
      previousParent ? navigate(previousParent) : navigate(-1)
      localStorage.removeItem('@LEKTO:Navigation')
    } else if (coMomentStatusState.length > 1) {
      navigate(`/home?coMomentStatus=${coMomentStatus}`)
    } else if (pathState.length > 1) {
      navigate(pathState)
    } else if (previousParent) {
      navigate(previousParent)
      localStorage.removeItem('@LEKTO:Navigation')
    } else {
      navigate(-1)
    }
  }

  return (
    <S.Container>
      {tooltipText ? (
        <Tooltip title={tooltipText}>
          <S.ButtonIcon data-testid="back-navigation-button" onClick={goBack}>
            {icon ? icon : <UilArrowLeft size={20} color="#0095ff" />}
          </S.ButtonIcon>
        </Tooltip>
      ) : (
        <>
          {small ? (
            <S.ButtonIcon data-testid="back-navigation-button" onClick={goBack}>
              {icon ? icon : <UilArrowLeft size={20} />}
            </S.ButtonIcon>
          ) : (
            <S.ButtonIconFull data-testid="back-navigation-button" onClick={goBack}>
              {icon ? icon : <UilArrowLeft size={20} />}
              <S.TextLabel>{!label && name}</S.TextLabel>
            </S.ButtonIconFull>
          )}
        </>
      )}

      <Tooltip title={label} arrow>
        <S.TitleLabel>{truncateText(label ?? '', 100)}</S.TitleLabel>
      </Tooltip>
    </S.Container>
  )
}
