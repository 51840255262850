import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { UilArrowRight, UilBookReader, UilCalender, UilExclamationCircle } from '@iconscout/react-unicons'
import { Tooltip } from '@mui/material'

import useWindowDimensions from '~/_hooks/useWindowDimensions'

import {
  AccordionTextComponent,
  AvatarGroupComponent,
  BackNavigationComponent,
  ButtonComponent,
  ChipComponent,
  SkeletonComponent,
} from '~/components'
import TagClass from '~/components/Cards/TagClass/TagClass'

import { mapCoStage } from '~/validations/mapCoStage'
import { truncateText } from '~/validations/truncateText'

import { IComponentProps } from '~/interfaces/Component'

import { ITrailsHeaderProps, Moment } from './Header.interfaces'
import * as S from './Header.styles'

export const TrailsHeaderComponent = ({
  actionOpenModalBNCC,
  actionOpenModalTime,
  txTitle,
  txDescription,
  component,
  professor,
  moment,
  loading,
  coStage,
  stage,
  txGrade,
  txClassShift,
  txSchoolGradeName,
  hasScrolled,
  isDisabledClasses,
}: ITrailsHeaderProps) => {
  const { classId } = useParams()

  const navigate = useNavigate()
  const { width } = useWindowDimensions()
  const isMobile = width && width < 768
  const userInfo = JSON.parse(localStorage?.getItem('@LEKTO:user') as string)
  const [popoverContent, setPopoverContent] = useState<string[]>([])
  const [showPopover, setShowPopover] = useState<boolean>(false)
  const teacherListName = professor?.map((teacher) => teacher.txName)

  const countSecComponents = contarSecComponentes(component ?? [])

  const [countTeacher, setCountTeacher] = useState(0)

  useEffect(() => {
    professor?.map((teacher) => {
      if (teacher.txName === userInfo.txName) {
        setCountTeacher(teacherListName?.length - 1)
      }
      if (teacher.txName !== userInfo.txName) {
        setCountTeacher(teacherListName?.length - 1)
      }
    })
  }, [professor, teacherListName?.length, userInfo.txName])

  function contarSecComponentes(components: IComponentProps[]) {
    let contador = 0
    components.forEach((item) => {
      if (item.coComponentType === 'SEC') {
        contador++
      }
    })

    return contador
  }

  const handleMouseEnter = (filteredComponents: IComponentProps[]) => {
    const content = filteredComponents.map((item: IComponentProps) => item?.txComponent ?? '')
    setPopoverContent(content)
    setShowPopover(true)
  }

  const handleMouseLeave = () => {
    setShowPopover(false)
  }

  function validateProfessors(moments: Moment[], loggedInUserId: number) {
    let containsEmptyProfessor = false
    const allOccupiedByLoggedInUser = true
    const coStageCheck = stage === 'F1' || stage === 'F2'

    if (moments) {
      for (const moment of moments) {
        if (!moment.professor) {
          containsEmptyProfessor = true
        } else if (moment.professor.idUserProfessor || moment.professor.idUser === loggedInUserId) {
          return true
        }
      }

      if (coStageCheck) {
        for (const professorData of professor) {
          if (professorData.idUser === loggedInUserId) {
            return true
          }
          if (professorData.idUser !== loggedInUserId) {
            return false
          }
        }
      }

      if (professor) {
        for (const professorData of professor) {
          if (professorData.idUser === loggedInUserId) {
            return true
          } else if (professorData.idUser !== loggedInUserId && !containsEmptyProfessor && !coStageCheck) {
            return false
          } else if (professorData.idUser !== loggedInUserId && containsEmptyProfessor && !coStageCheck) {
            return true
          }
        }
      }
    }

    if (!allOccupiedByLoggedInUser && containsEmptyProfessor) {
      return true
    }

    if (allOccupiedByLoggedInUser) {
      return true
    }

    if (containsEmptyProfessor) {
      return true
    }

    return false
  }

  const allWithProfessor = validateProfessors(moment ? moment : [], userInfo?.id_user)
  const previousParent = localStorage.getItem('@LEKTO:Navigation')

  return (
    <S.ContentHeader hasScroll={hasScrolled}>
      {hasScrolled ? (
        <>
          <S.HeaderFlex>
            <S.Return>
              <BackNavigationComponent
                path={previousParent ? previousParent : `/class-details/${classId}`}
                label={truncateText(txTitle, 85)}
                small
              />
            </S.Return>
          </S.HeaderFlex>

          <S.HeaderInfo>
            <S.TrackDescription>
              <S.ContainerClassInfoScrolled>
                {loading ? (
                  <>
                    <SkeletonComponent variant="rounded" height={50} width={'80%'} />
                  </>
                ) : (
                  <TagClass texts={[txClassShift as string, mapCoStage(coStage), txGrade, txSchoolGradeName]} />
                )}

                <S.Wrapper>
                  {loading ? (
                    <>
                      {Object.keys(new Array(3).fill(null)).map((_, index: number) => (
                        <SkeletonComponent key={index} variant="rounded" height={26} width={70} />
                      ))}
                    </>
                  ) : (
                    <>
                      {component
                        ?.filter((item: IComponentProps) => item.coComponentType === 'PRI')
                        .map((item: IComponentProps, index: number) => (
                          <S.ComponentContentPopover
                            key={index}
                            onMouseEnter={() => handleMouseEnter(component)}
                            onMouseLeave={handleMouseLeave}
                          >
                            <ChipComponent
                              key={index}
                              type="normal"
                              label={item?.txComponent}
                              count={countSecComponents}
                              isSametag={true}
                            />
                            {showPopover && (
                              <S.ContainerPopover>
                                <S.TitleTooltip>Componentes dessa aula:</S.TitleTooltip>
                                <S.ContentTooltip>
                                  {popoverContent?.map((content, index) => (
                                    <>
                                      <S.TextTooltip
                                        key={index}
                                        className={index === popoverContent?.length - 1 ? 'last-item' : ''}
                                      >
                                        {index === 0 ? '' : index === popoverContent?.length - 1 ? ' e ' : ', '}
                                        {content}
                                      </S.TextTooltip>
                                    </>
                                  ))}
                                </S.ContentTooltip>
                                {isMobile ? (
                                  <Tooltip title="Acessar Integração com o Currículo">
                                    <S.ButtonIcon onClick={actionOpenModalBNCC}>
                                      <UilBookReader size={16} color="#0095ff" />
                                    </S.ButtonIcon>
                                  </Tooltip>
                                ) : (
                                  <ButtonComponent
                                    text="Acessar Integração com o Currículo"
                                    variant="outline"
                                    size="small"
                                    iconEnd={<UilArrowRight size="18" />}
                                    fontWeight="normal"
                                    onClick={actionOpenModalBNCC}
                                  />
                                )}
                              </S.ContainerPopover>
                            )}
                          </S.ComponentContentPopover>
                        ))}
                    </>
                  )}
                </S.Wrapper>

                {loading ? (
                  <>
                    <SkeletonComponent variant="circular" height={32} width={32} />
                    <SkeletonComponent variant="rounded" height={32} width={'100%'} />
                  </>
                ) : (
                  <>
                    {professor && (
                      <Tooltip
                        title={
                          <ul>
                            {teacherListName?.map((item, index) => (
                              <li key={index}>{item}</li>
                            ))}
                          </ul>
                        }
                        placement="top"
                      >
                        <S.ConteinerProfessor>
                          <AvatarGroupComponent images={professor} size="xsmall" max={4} />
                          <S.NameProfessor>
                            {countTeacher
                              ? `${truncateText(professor[0].txName, 15)} +${countTeacher}`
                              : truncateText(professor[0].txName, 15)}
                          </S.NameProfessor>
                        </S.ConteinerProfessor>
                      </Tooltip>
                    )}
                  </>
                )}
              </S.ContainerClassInfoScrolled>
            </S.TrackDescription>
          </S.HeaderInfo>
        </>
      ) : (
        <>
          <S.HeaderFlex>
            <S.Return>
              <BackNavigationComponent
                name={'Todas as Trilhas'}
                path={previousParent ? previousParent : `/class-details/${classId}`}
              />
            </S.Return>

            <S.HeaderBtns>
              {loading ? (
                <>
                  <SkeletonComponent variant="circular" height={32} width={32} />
                </>
              ) : (
                <>
                  <ButtonComponent
                    variant="outline"
                    size="small"
                    iconStart={<UilCalender size={16} />}
                    text="Alterar Datas"
                    fontWeight="normal"
                    color={!allWithProfessor ? 'disabled' : ''}
                    disabled={!allWithProfessor}
                    onClick={actionOpenModalTime}
                  />
                </>
              )}
            </S.HeaderBtns>
          </S.HeaderFlex>
          <S.HeaderTitleDiv>
            {loading ? (
              <>
                <SkeletonComponent variant="rounded" height={50} width={'80%'} />
              </>
            ) : (
              <>
                <S.HeaderTitle>{txTitle}</S.HeaderTitle>
              </>
            )}
          </S.HeaderTitleDiv>

          <S.HeaderInfo>
            <S.TrackDescription>
              <S.ContainerClassInfo>
                <S.Wrapper>
                  {loading ? (
                    <>
                      <SkeletonComponent variant="rounded" height={50} width={'80%'} />
                    </>
                  ) : (
                    <TagClass texts={[txClassShift as string, mapCoStage(coStage), txGrade, txSchoolGradeName]} />
                  )}
                  {loading ? (
                    <>
                      {Object.keys(new Array(3).fill(null)).map((_, index: number) => (
                        <SkeletonComponent key={index} variant="rounded" height={26} width={70} />
                      ))}
                    </>
                  ) : (
                    <>
                      {component
                        ?.filter((item: IComponentProps) => item.coComponentType === 'PRI')
                        .map((item: IComponentProps, index: number) => (
                          <S.ComponentContentPopover
                            key={index}
                            onMouseEnter={() => handleMouseEnter(component)}
                            onMouseLeave={handleMouseLeave}
                          >
                            <ChipComponent
                              key={index}
                              type="normal"
                              label={item?.txComponent}
                              count={countSecComponents}
                              isSametag={true}
                            />
                            {showPopover && (
                              <S.ContainerPopover>
                                <S.TitleTooltip>Componentes dessa aula:</S.TitleTooltip>
                                <S.ContentTooltip>
                                  {popoverContent?.map((content, index) => (
                                    <>
                                      <S.TextTooltip
                                        key={index}
                                        className={index === popoverContent?.length - 1 ? 'last-item' : ''}
                                      >
                                        {index === 0 ? '' : index === popoverContent?.length - 1 ? ' e ' : ', '}
                                        {content}
                                      </S.TextTooltip>
                                    </>
                                  ))}
                                </S.ContentTooltip>
                                {isMobile ? (
                                  <Tooltip title="Acessar Integração com o Currículo">
                                    <S.ButtonIcon onClick={actionOpenModalBNCC}>
                                      <UilBookReader size={16} color="#0095ff" />
                                    </S.ButtonIcon>
                                  </Tooltip>
                                ) : (
                                  <ButtonComponent
                                    text="Acessar Integração com o Currículo"
                                    variant="outline"
                                    size="small"
                                    iconEnd={<UilArrowRight size="18" />}
                                    fontWeight="normal"
                                    onClick={actionOpenModalBNCC}
                                  />
                                )}
                              </S.ContainerPopover>
                            )}
                          </S.ComponentContentPopover>
                        ))}
                    </>
                  )}
                  {loading ? (
                    <SkeletonComponent variant="circular" height={32} width={32} />
                  ) : (
                    <>
                      {professor && (
                        <Tooltip
                          title={
                            <ul>
                              {teacherListName?.map((item, index) => (
                                <li key={index}>{item}</li>
                              ))}
                            </ul>
                          }
                          placement="top"
                        >
                          <S.ConteinerProfessor>
                            <AvatarGroupComponent images={professor} size="xsmall" max={4} />
                            <S.NameProfessor countTeacher={countTeacher > 0}>{professor[0].txName}</S.NameProfessor>
                            <S.CountProfessor>{countTeacher > 0 && ` +${countTeacher}`}</S.CountProfessor>
                          </S.ConteinerProfessor>
                        </Tooltip>
                      )}
                    </>
                  )}
                </S.Wrapper>
              </S.ContainerClassInfo>

              {txDescription && <AccordionTextComponent paragraph={txDescription} limit={120} />}
            </S.TrackDescription>
          </S.HeaderInfo>

          {moment?.every((track) => track.coMomentStatus === 'AGEN') && (
            <S.NotGeneratedWaring>
              <UilExclamationCircle size={20} />
              <S.Span>A plataforma ainda não gerou as aulas para essa trilha.</S.Span>
            </S.NotGeneratedWaring>
          )}
        </>
      )}
    </S.ContentHeader>
  )
}
