import { Box, Tooltip, Typography, Button } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'
import styled from 'styled-components'

import theme from '~/styles/theme'

export const ComponentContentPopover = styledMUI(Box)(() => ({
  display: 'flex',
}))

export const ContainerPopover = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  gap: '8px',
  width: '334px',
  marginTop: '24px',
  borderRadius: '8px',
  padding: '16px',
  backgroundColor: `${theme.colors.highPure}`,
  boxShadow: `0px 4px 4px ${theme.shadows['shadow-level-4']}`,
  transition: '0.8',
  zIndex: 9999,
}))

export const TitleTooltip = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: ${theme.colors.lowLight};
`

export const ContentTooltip = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`

export const TextTooltip = styled.span`
  flex-direction: row-reverse;
  gap: 8px;
  display: flex;
  font-size: 14px;
  &.last-item {
    margin-left: 5px;
  }
`

export const ButtonIcon = styledMUI(Button)(() => ({
  height: '32px',
  width: '32px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50px',
  border: `1px solid ${theme.colors.actionPure}`,
  backgroundColor: `${theme.colors.highPure}`,
  padding: 0,
}))

export const ContentHeader = styled(Box)<{ hasScroll?: boolean }>(({ hasScroll }) => ({
  display: 'flex',
  flexDirection: 'column',
  zIndex: 100,
  padding: hasScroll ? '16px 24px' : '24px',
  width: '923px',
  gap: '16px',
  backgroundColor: '#fff',
  position: hasScroll ? 'fixed' : 'relative',
  '@media (max-width: 600px)': {
    width: '100%',
  },
}))

export const HeaderDiv = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}))

export const HeaderBtns = styledMUI(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '16px',
}))

export const HeaderTitle = styledMUI(Typography)(() => ({
  fontstyle: 'normal',
  fontWeight: 700,
  fontSize: '24px',
  lineHeight: '120%',
}))

export const HeaderTitleDiv = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
}))

export const HeaderInfo = styledMUI(Box)(() => ({
  width: '100%',
  gap: '16px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  '@media (max-width: 600px)': {
    display: 'grid',
  },
}))

export const TooltipLabel = styledMUI(Tooltip)(() => ({}))

export const Return = styledMUI(Box)(() => ({
  display: 'flex',
  gap: '8px',
  color: '#000',
  backgroundColor: 'inherit',
  border: 'none',
  alignItems: 'center',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '120%',
  cursor: 'pointer',
}))

export const TrackNumber = styledMUI(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#F9FAFC',
  color: '#666',
  height: '40px',
  width: '40px',
  borderRadius: '50%',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '100%',
}))

export const TrackTimes = styledMUI(Box)(() => ({
  backgroundColor: '#F9FAFC',
  display: 'flex',
  borderRadius: '8px',
  gap: '8px',
  padding: '8px',
  flexDirection: 'column',
  marginRight: '16px',
  height: '160px',
}))

export const TrackDescription = styledMUI(Box)(() => ({
  padding: '6px',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  gap: '20px',
  justifyContent: 'start',
  '@media (max-width: 600px)': {
    width: '100%',
    flexDirection: 'column',
  },
}))
export const ConteinerProfessorScrolled = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '4px',
  border: `1px solid ${theme.colors.lowLight}`,
  borderRadius: '4px',
  padding: '4px',
  height: '30px',
}))
export const ConteinerProfessor = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '4px',
  border: `1px solid ${theme.colors.lowLight}`,
  borderRadius: '4px',
  padding: '4px',
  height: '24px',
}))

export const NameProfessor2 = styledMUI(Typography)<{ isSameUser?: boolean }>(({ isSameUser }) => ({
  maxWidth: isSameUser ? '82px' : '135px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  WebkitLineClamp: 1,
  whiteSpace: 'nowrap',
  fontSize: '14px',
  color: `${theme.colors.lowPure}`,
  fontWeight: 700,
  alignItems: 'center',
  span: {
    color: `${theme.colors.lowLight}`,
    fontSize: '14px',
    fontWeight: 400,
  },
}))

export const NameProfessor = styled.span<{ countTeacher?: boolean }>`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: ${({ countTeacher }) => (countTeacher ? '98px' : '119px')};
`

export const CountProfessor = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 100%;
`

export const ContainerClassInfoScrolled = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`
export const ContainerClassInfo = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const WrapperClass = styledMUI(Box)(() => ({
  borderRadius: '4px',
  border: '1px solid #7C8189',
  borderColor: '#7C8189',
  color: '#7C8189',
  fontSize: '14px',
  fontWeight: 400,
  display: 'flex',
  alignItems: 'center',
  padding: '4px 10px',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  maxWidth: '340px',

  gap: '8px',
  '@media (max-width: 600px)': {
    flexWrap: 'wrap',
  },
}))

export const ContainerTrackClass = styled.div<{
  isScrolled?: boolean
}>`
  display: flex;
  align-items: center;
  border: 1px solid #7c8189;
  height: ${({ isScrolled }) => (isScrolled ? '24px' : '30px')};
  padding: 4px 6px;
  border-radius: 4px;
  span:last-child {
    border-right: none;
  }
`

export const ClassSpanInfo = styled.span`
  font-size: 14px;
  padding: 0 6px;
  color: #7c8189;
  border-right: 1px solid #e2e5ec;
  min-width: fit-content;

  &:last-child {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    min-width: auto;
    max-width: 100px;
  }
`
export const ClassSpanInative = styled.span`
  font-size: 14px;
  padding: 0 6px;
  color: #7c8189;
  border-right: 1px solid #e2e5ec;
`

export const TrackClass = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '120%',
  color: '#666',
}))

export const TrackSkill = styledMUI(Box)(() => ({
  padding: '6px',
  backgroundColor: '#0095FF',
  color: '#fff',
  width: '100px',
  textAlign: 'center',
  borderRadius: '4px',
  borderColor: 'transparent',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '100%',
}))

export const Professor = styledMUI(Box)(() => ({
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '100%',
  color: '${({ theme }) => theme.colors.lowPure}',
}))

export const EditTime = styledMUI(Typography)(() => ({
  color: '#0095FF',
  alignSelf: 'center',
  cursor: 'pointer',
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '100%',
}))

export const NotGeneratedWaring = styledMUI(Box)(() => ({
  alignItems: 'center',
  backgroundColor: '#FF8A00',
  fontStyle: 'normal',
  borderRadius: '4px',
  color: '#FFFFFF',
  display: 'flex',
  fontSize: '14px',
  fontWeight: 700,
  gap: '8px',
  lineHeight: '120%',
  padding: '8px 16px',
  '@media (max-width: 600px)': {
    width: '100%',
  },
}))

export const Span = styledMUI(Typography)(() => ({}))

export const ArrowIcon = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: '1px solid #0095FF',
  color: '#0095FF',
  borderRadius: '50px',
  height: '32px',
  width: '32px',
}))

export const HeaderFlex = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}))

export const Wrapper = styledMUI(Box)(() => ({
  flexWrap: 'wrap',
  alignItems: 'center',
  display: 'flex',
  gap: '8px',
}))

export const ContentSchedules = styledMUI(Box)(() => ({
  height: 'max-content',
  width: '194px',
  display: 'flex',
  flexDirection: 'column',
  padding: '8px',
  gap: '8px',
  backgroundColor: '#F9FAFC',
  borderRadius: '8px',
  '@media (max-width: 600px)': {
    width: 'auto',
  },
}))

export const TextSchedules = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '100%',
  color: '#000',
}))

export const ContentChipsSchedules = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  '@media (max-width: 600px)': {
    display: 'grid',
    width: '100%',
  },
}))
