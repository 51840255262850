import { Box, Typography, Button } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'
import styled from 'styled-components'

import theme from '~/styles/theme'

export const ComponentContentPopover = styledMUI(Box)(() => ({
  display: 'flex',
}))

export const ContainerPopover = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  gap: '8px',
  width: '334px',
  marginTop: '24px',
  borderRadius: '8px',
  padding: '16px',
  backgroundColor: `${theme.colors.highPure}`,
  boxShadow: `0px 4px 4px ${theme.shadows['shadow-level-4']}`,
  transition: '0.8',
  zIndex: 9,
}))

export const TitleTooltip = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: ${theme.colors.lowLight};
`

export const ContentTooltip = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`

export const TextTooltip = styled.span`
  flex-direction: row-reverse;
  gap: 8px;
  display: flex;
  font-size: 14px;
  &.last-item {
    margin-left: 5px;
  }
`

export const ButtonIcon = styledMUI(Button)(() => ({
  height: '32px',
  width: '32px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50px',
  border: `1px solid ${theme.colors.actionPure}`,
  backgroundColor: `${theme.colors.highPure}`,
  padding: 0,
}))

export const ContentHeader = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  padding: '24px',
  backgroundColor: '#fff',
  '@media (max-width: 600px)': {
    width: '100%',
  },
}))

export const HeaderBtns = styledMUI(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '16px',
}))

export const HeaderTitle = styledMUI(Typography)(() => ({
  fontstyle: 'normal',
  fontWeight: 700,
  fontSize: '24px',
  lineHeight: '120%',
}))

export const HeaderTitleDiv = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}))

export const HeaderInfo = styledMUI(Box)(() => ({
  width: '100%',
  display: 'flex',
  gap: '12px',
  justifyContent: 'space-between',
  '@media (max-width: 600px)': {
    display: 'grid',
  },
}))

export const Return = styledMUI(Box)(() => ({
  display: 'flex',
  gap: '8px',
  color: '#000',
  backgroundColor: 'inherit',
  border: 'none',
  alignItems: 'center',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '120%',
  cursor: 'pointer',
}))

export const TrackDescription = styledMUI(Box)(() => ({
  padding: '6px',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  gap: '16px',
  justifyContent: 'start',
  alignItems: 'center',
  '@media (max-width: 600px)': {
    width: '100%',
  },
}))

export const NotGeneratedWaring = styledMUI(Box)(() => ({
  alignItems: 'center',
  backgroundColor: '#FF8A00',
  fontStyle: 'normal',
  borderRadius: '4px',
  color: '#FFFFFF',
  display: 'flex',
  fontSize: '14px',
  fontWeight: 700,
  gap: '8px',
  lineHeight: '120%',
  padding: '8px 16px',
  '@media (max-width: 600px)': {
    width: '100%',
  },
}))

export const Span = styledMUI(Typography)(() => ({}))

export const HeaderFlex = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}))

export const Wrapper = styledMUI(Box)(() => ({
  flexWrap: 'wrap',
  alignItems: 'center',
  display: 'flex',
  gap: '8px',
}))

export const ConteinerProfessor = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '4px',
  border: `1px solid ${theme.colors.lowLight}`,
  borderRadius: '4px',
  padding: '4px',
  height: '24px',
}))

export const TextInfoLabel = styledMUI(Typography)(() => ({
  fontSize: '14px',
  color: `${theme.colors.lowPure}`,
  fontWeight: 700,
  alignItems: 'center',
  span: {
    color: `${theme.colors.lowLight}`,
    fontSize: '14px',
    fontWeight: 400,
  },
}))

export const NameProfessor = styled.span<{ countTeacher?: boolean }>`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: ${({ countTeacher }) => (countTeacher ? '98px' : '119px')};
`

export const CountProfessor = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 100%;
`
